.logo {
  width: 125px;
}

.v-separator {
  padding-left: 6px;
  padding-right: 6px;
}

.nav-tab {
  text-transform: uppercase;
}

.nav-tab.active {
  font-weight: 700 !important;
}

.h-separator {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #000 !important;
  margin: 1em 0 .8em 0 !important;
  padding: 0;
}

.dropdown-toggle {
	background: none !important;
	color: inherit !important;
	border: none !important;
	padding: 0 !important;
	font: inherit !important;
	cursor: pointer !important;
	outline: inherit !important;
  text-decoration: underline;
}