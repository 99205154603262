@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

body {
  font-family: 'Inter', sans-serif !important;
  font-weight: 400 !important;
  background-color: white !important;
  color: black !important;
  font-size: 0.9em !important;
}

a {
  color: black !important;
  font-weight: 500 !important;
}

a:hover {
  color: #6B6B6B !important;
}

/* Backdrop for modals */

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.1);
  backdrop-filter: blur(5px);
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.backdrop > .card {
  display: block;
  position: absolute;
  width: 40%; 
  max-height: 80%;
  z-index: 2;
}