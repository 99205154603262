.alert {
  position: fixed !important;
  top: 20px;
  width: 50%;
  left: 25%;
}

@media (max-width: 768px) {
  .alert {
    width: 90%;
    left: 5%
  }
}